import { inject }                from 'aurelia-framework';
import { EditConcession }        from 'modules/management/specifications/concessions/edit';
import { FormSchema }            from 'modules/management/concessions/concessions-tree/concessions/form-schema';
import { ConcessionsRepository } from 'modules/management/specifications/concessions/services/repository';
import { AppContainer }          from 'resources/services/app-container';

@inject(AppContainer, ConcessionsRepository, FormSchema)
export class EditConcessionTree extends EditConcession {

    formId = 'management.concessions.concessions.edition-form';

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer, repository, formSchema);
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.specifications.concessions.manage',
            'management.specifications.concessions.edit',
        ]);
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    submit() {
        this.alert = null;

        this.repository
            .update(this.model.id, this.model)
            .then((response) => {
                this.handleResponse(response, 'management.concessions.concessions.index');
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }
}
