import { inject }                from 'aurelia-framework';
import { CreateConcession }      from 'modules/management/specifications/concessions/create';
import { FormSchema }            from 'modules/management/concessions/concessions-tree/concessions/form-schema';
import { ConcessionsRepository } from 'modules/management/specifications/concessions/services/repository';
import { AppContainer }          from 'resources/services/app-container';

@inject(AppContainer, ConcessionsRepository, FormSchema)
export class CreateConcessionTree extends CreateConcession {

    formId = 'management.concessions.concessions.creation-form';

    create = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer, repository, formSchema);
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.specifications.concessions.manage',
            'management.specifications.concessions.create',
        ]);
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.create(this.model)
            .then((response) => {
                this.handleResponse(response, 'management.concessions.concessions.index');
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }
}
