import { inject }                from 'aurelia-framework';
import { FormSchema }            from 'modules/management/concessions/concessions-tree/concessions/form-schema';
import { ConcessionsRepository } from 'modules/management/specifications/concessions/services/repository';
import { ViewConcession }        from 'modules/management/specifications/concessions/view';
import { AppContainer }          from 'resources/services/app-container';

@inject(AppContainer, ConcessionsRepository, FormSchema)
export class ViewConcessionTree extends ViewConcession {
    formId = 'management.concessions.concessions.view-form';

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer, repository, formSchema);
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.specifications.concessions.manage',
            'management.specifications.concessions.view',
        ]);
    }
}
