import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class HighwaysRepository extends BaseRepository {
    baseUrl = 'management/concessions/concessions/highways';

    /**
     * Creates a record
     *
     * @param data
     *
     * @param concessionId
     * @returns {*}
     */
    create(data, concessionId) {
        return this.httpClient.post(`management/concessions/concessions/${concessionId}/highways`, data);
    }

    /**
     * Returns all intervention types for a given highway
     *
     * @param highwayId
     * @param criteria
     * @return {*}
     */
    interventionTypes(highwayId, criteria) {
        return this.httpClient.post(`${this.baseUrl}/${highwayId}/intervention-types`, criteria);
    }

    /**
     * Returns next intervention number
     *
     * @param highwayId
     * @return {*}
     */
    nextInterventionNumber(highwayId) {
        return this.httpClient.get(`${this.baseUrl}/${highwayId}/next-intervention-number`);
    }
}
